import { FC } from "react";

interface IProps {
  width?: string;
  height?: string;
  textColor?: string;
}

const Logo: FC<IProps> = ({
  width = "79",
  height = "33",
  textColor = "#303030",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.4585 43.1916C93.0417 43.1916 90.973 42.713 89.2522 41.756C87.5315 40.7893 86.2119 39.4456 85.2936 37.7248C84.3752 35.9944 83.916 33.9885 83.916 31.7071C83.916 29.4257 84.3752 27.4246 85.2936 25.7039C86.2119 23.9735 87.5315 22.6297 89.2522 21.6727C90.973 20.706 93.0417 20.2227 95.4585 20.2227C97.8752 20.2227 99.944 20.706 101.665 21.6727C103.385 22.6297 104.705 23.9735 105.623 25.7039C106.542 27.4246 107.001 29.4257 107.001 31.7071C107.001 33.9885 106.542 35.9944 105.623 37.7248C104.705 39.4456 103.385 40.7893 101.665 41.756C99.944 42.713 97.8752 43.1916 95.4585 43.1916ZM95.5165 37.2753C96.1932 37.2753 96.778 37.0481 97.271 36.5938C97.7641 36.1394 98.1459 35.4918 98.4166 34.6507C98.6873 33.8097 98.8226 32.8091 98.8226 31.6491C98.8226 30.4794 98.6873 29.4789 98.4166 28.6475C98.1459 27.8065 97.7641 27.1588 97.271 26.7044C96.778 26.2501 96.1932 26.0229 95.5165 26.0229C94.8011 26.0229 94.1873 26.2501 93.6749 26.7044C93.1625 27.1588 92.771 27.8065 92.5004 28.6475C92.2297 29.4789 92.0943 30.4794 92.0943 31.6491C92.0943 32.8091 92.2297 33.8097 92.5004 34.6507C92.771 35.4918 93.1625 36.1394 93.6749 36.5938C94.1873 37.0481 94.8011 37.2753 95.5165 37.2753Z"
        fill={textColor}
      />
      <path
        d="M69.8757 51.6019C67.6426 51.6019 65.7285 51.2829 64.1334 50.6448C62.548 50.0165 61.3155 49.1465 60.4358 48.0347C59.5658 46.9327 59.0776 45.6663 58.9712 44.2356H66.6855C66.7629 44.7093 66.9562 45.0911 67.2656 45.3811C67.5749 45.6712 67.9713 45.879 68.4546 46.0047C68.9476 46.14 69.4987 46.2077 70.1077 46.2077C71.142 46.2077 71.9976 45.9563 72.6743 45.4536C73.3606 44.951 73.7038 44.0423 73.7038 42.7275V38.9574H73.4718C73.1818 39.7114 72.7371 40.3543 72.1378 40.886C71.5384 41.408 70.8182 41.8092 69.9772 42.0895C69.1361 42.3602 68.2129 42.4955 67.2076 42.4955C65.6222 42.4955 64.1624 42.1282 62.8284 41.3935C61.504 40.6588 60.4406 39.4939 59.6383 37.8988C58.8456 36.3038 58.4492 34.2205 58.4492 31.6491C58.4492 28.9617 58.8649 26.7769 59.6963 25.0948C60.5373 23.4128 61.62 22.1802 62.9444 21.3972C64.2784 20.6142 65.6802 20.2227 67.1496 20.2227C68.2516 20.2227 69.2183 20.416 70.0497 20.8027C70.881 21.1797 71.5771 21.6872 72.1378 22.3252C72.6984 22.9536 73.1238 23.6448 73.4138 24.3988H73.5878V20.5127H81.5921V42.7275C81.5921 44.6126 81.1039 46.2125 80.1276 47.5272C79.1609 48.8516 77.7978 49.8618 76.0384 50.5578C74.279 51.2539 72.2248 51.6019 69.8757 51.6019ZM70.1657 36.8113C70.9004 36.8113 71.5287 36.6083 72.0508 36.2023C72.5824 35.7866 72.9885 35.1969 73.2688 34.4332C73.5588 33.6598 73.7038 32.7318 73.7038 31.6491C73.7038 30.5471 73.5588 29.6045 73.2688 28.8215C72.9885 28.0288 72.5824 27.4246 72.0508 27.0089C71.5287 26.5836 70.9004 26.3709 70.1657 26.3709C69.431 26.3709 68.8026 26.5836 68.2806 27.0089C67.7683 27.4246 67.3719 28.0288 67.0916 28.8215C66.8209 29.6045 66.6855 30.5471 66.6855 31.6491C66.6855 32.7511 66.8209 33.6888 67.0916 34.4622C67.3719 35.2259 67.7683 35.8108 68.2806 36.2168C68.8026 36.6131 69.431 36.8113 70.1657 36.8113Z"
        fill={textColor}
      />
      <path
        d="M41.6742 30.257V42.7855H33.6699V20.5127H41.2682V24.7468H41.5002C41.9836 23.3354 42.8343 22.2286 44.0523 21.4262C45.28 20.6238 46.7108 20.2227 48.3445 20.2227C49.9202 20.2227 51.2881 20.5852 52.4482 21.3102C53.6179 22.0256 54.5217 23.0116 55.1598 24.2683C55.8075 25.525 56.1265 26.9606 56.1168 28.575V42.7855H48.1125V30.257C48.1222 29.155 47.8418 28.2898 47.2715 27.6614C46.7108 27.0331 45.9277 26.7189 44.9224 26.7189C44.265 26.7189 43.6898 26.8639 43.1968 27.1539C42.7134 27.4343 42.3413 27.8403 42.0803 28.372C41.8192 28.894 41.6839 29.5224 41.6742 30.257Z"
        fill={textColor}
      />
      <path
        d="M22.5137 42.785V20.5122H30.518V42.785H22.5137ZM26.5158 18.1921C25.4331 18.1921 24.5051 17.8344 23.7317 17.119C22.9584 16.4037 22.5717 15.5433 22.5717 14.5379C22.5717 13.5326 22.9584 12.6722 23.7317 11.9568C24.5051 11.2415 25.4331 10.8838 26.5158 10.8838C27.6082 10.8838 28.5362 11.2415 29.2999 11.9568C30.0733 12.6722 30.46 13.5326 30.46 14.5379C30.46 15.5433 30.0733 16.4037 29.2999 17.119C28.5362 17.8344 27.6082 18.1921 26.5158 18.1921Z"
        fill={textColor}
      />
      <path
        d="M0 42.7851V13.0879H8.06232V36.2888H20.0688V42.7851H0Z"
        fill={textColor}
      />
      <path
        d="M102.285 10.1982C103.181 4.5659 108.474 0.726726 114.106 1.62319L141.954 6.05546L160.976 9.08319L166.342 9.93717L177.825 25.7683L176.739 32.597L175.101 42.8848C174.205 48.5171 168.912 52.3562 163.28 51.4598L107.585 42.5952C101.953 41.6988 98.1137 36.4061 99.0101 30.7738L102.285 10.1982Z"
        fill="#3F97FF"
      />
      <path
        d="M148.957 43.3157C148.299 43.2109 147.68 43.0592 147.101 42.8604C146.52 42.6686 146.019 42.4502 145.596 42.2053L147.492 38.3299C147.936 38.5711 148.349 38.7469 148.729 38.8572C149.116 38.9685 149.457 38.9943 149.75 38.9345C150.051 38.8758 150.287 38.7108 150.458 38.4397L150.629 38.1685L147.67 20.6055L153.656 21.5583L154.473 33.0263L154.64 33.0527L159.019 22.4118L165.046 23.3712L156.474 39.7383C156.043 40.5719 155.495 41.2944 154.829 41.906C154.169 42.5256 153.36 42.9686 152.401 43.2351C151.442 43.5017 150.294 43.5285 148.957 43.3157Z"
        fill="white"
      />
      <path
        d="M137.234 35.6107C135.502 35.335 134.074 34.7561 132.95 33.8739C131.827 32.9847 131.035 31.8711 130.573 30.5331C130.112 29.1882 130.012 27.6981 130.272 26.063C130.532 24.4279 131.09 23.0461 131.944 21.9176C132.8 20.7822 133.899 19.9696 135.241 19.48C136.585 18.9835 138.122 18.873 139.855 19.1487C141.587 19.4244 143.014 20.0068 144.137 20.896C145.261 21.7782 146.054 22.8917 146.515 24.2367C146.977 25.5747 147.077 27.0613 146.817 28.6964C146.557 30.3315 145.999 31.7168 145.143 32.8522C144.289 33.9807 143.19 34.7933 141.846 35.2898C140.504 35.7794 138.967 35.8864 137.234 35.6107ZM137.951 31.3771C138.436 31.4543 138.881 31.3582 139.286 31.0888C139.691 30.8194 140.039 30.3988 140.329 29.8269C140.619 29.255 140.83 28.5533 140.962 27.7219C141.096 26.8836 141.113 26.151 141.014 25.5243C140.916 24.8907 140.716 24.3829 140.414 24.001C140.113 23.6191 139.72 23.3896 139.235 23.3124C138.722 23.2308 138.256 23.3236 137.837 23.5908C137.418 23.858 137.063 24.2775 136.773 24.8494C136.485 25.4144 136.273 26.116 136.14 26.9544C136.008 27.7858 135.991 28.5183 136.089 29.152C136.187 29.7856 136.393 30.2945 136.709 30.6786C137.024 31.0627 137.438 31.2955 137.951 31.3771Z"
        fill="white"
      />
      <path
        d="M125.434 11.6113L131.129 12.5178L128.807 27.1091C128.579 28.4937 128.035 29.6574 127.175 30.6002C126.322 31.5373 125.243 32.2037 123.937 32.5997C122.639 32.9898 121.203 33.0597 119.63 32.8094C118.265 32.5921 117.056 32.1581 116.001 31.5072C114.948 30.8494 114.167 29.9577 113.657 28.8323C113.148 27.6999 113.027 26.3167 113.294 24.6827L119.072 25.6024C119.008 26.1392 119.027 26.6039 119.128 26.9964C119.235 27.3901 119.42 27.7037 119.681 27.937C119.95 28.1646 120.296 28.312 120.719 28.3793C121.149 28.4476 121.524 28.4115 121.845 28.2707C122.172 28.1311 122.442 27.8898 122.652 27.5468C122.864 27.1969 123.017 26.7489 123.111 26.2026L125.434 11.6113Z"
        fill="white"
      />
      <path
        d="M166.342 9.93652L177.825 25.7676L170.967 24.676C167.212 24.0784 164.653 20.5499 165.25 16.7951L166.342 9.93652Z"
        fill="#2876D1"
      />
    </svg>
  );
};

export default Logo;
