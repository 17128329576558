import { FC } from "react";

interface IProps {
  width?: string;
  height?: string;
}

const PaperBG: FC<IProps> = ({ width = "351", height = "420" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 351 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M329 419.918C341.319 418.902 351 408.581 351 396L351 24C351 10.7452 340.255 -1.15877e-06 327 0L24 2.64891e-05C10.7452 2.76479e-05 -1.15877e-06 10.7452 0 24L3.25213e-05 396C3.35913e-05 408.239 9.16175 418.339 21.0013 419.815C21.1007 412.168 27.3301 406 35.0001 406C42.7321 406 49.0001 412.268 49.0001 420H68.0001C68.0001 412.268 74.2681 406 82.0001 406C89.7321 406 96.0001 412.268 96.0001 420H114C114 412.268 120.268 406 128 406C135.732 406 142 412.268 142 420H161C161 412.268 167.268 406 175 406C182.732 406 189 412.268 189 420H208C208 412.268 214.268 406 222 406C229.732 406 236 412.268 236 420H254C254 412.268 260.268 406 268 406C275.732 406 282 412.268 282 420H301C301 412.268 307.268 406 315 406C322.705 406 328.956 412.224 329 419.918Z"
        fill="white"
      />
    </svg>
  );
};

export default PaperBG;
